import React, { Component } from "react";
import { Link,  } from "react-router-dom";
import { Affix, Input, Select, Row, Col, Dropdown, Menu } from "antd";

import * as Const from "../../common/const";
import * as TreeHelper from "../../common/tree";

import { MenuService as ContentMenuService, ContentURL } from "../content/contentService";
import { MenuService as ProductMenuService, ProductURL } from "../product/productService"
import { Format } from "../../common/format";

export default class MenuMain extends Component {
	constructor(props) {
		super(props);

		this.state = {
			contentCategoryList: [],
			productCategoryTree: [],
		};
	}

	componentDidMount() {
		ContentMenuService.categoryGetList({ parentId: Const.pageCategory, idList: Const.pageMainMenu }).then((res) => {
			if (!res.success) return;

			this.setState({ contentCategoryList: res.itemList ? res.itemList : [] });
		});

		ProductMenuService.categoryTree().then((res) => {
			if (!res.success) return;

			this.setState({ productCategoryTree: TreeHelper.parseTree(res.itemList) });
		});
	}

	render() {
		const { contentCategoryList, productCategoryTree } = this.state;

		return (
			<div className="header-mainbar-menu">
				<Link to="/">
					<span>Trang chủ</span>
				</Link>
				{productCategoryTree.map((item_1) =>
					item_1.children.length > 0 ? (
						<Dropdown 
							key={item_1.key}
							overlay={
								<Menu> {item_1.children.map((item_2) => (
									<Menu.Item key={item_2.key}>
										<Link className="ant-dropdown-link"
											to={ProductURL.productListURL({}, { categoryName: item_2.title, categoryId: item_2.value })}>
											{item_2.title}
										</Link>
									</Menu.Item>
								))}
								</Menu>
							}>
							<Link to={ProductURL.productListURL({}, { categoryName: item_1.title, categoryId: item_1.value })}>
								<span>{item_1.title}</span>
									</Link>
										</Dropdown>
							) : (
							<Link
								key={"houseCategorySale-" + item_1.key}
									to={ProductURL.productListURL({}, { categoryName: item_1.title, categoryId: item_1.value })}
							>
								<span>{item_1.title}</span>
							</Link>
							)
				)}
				
				{contentCategoryList.map((item) => (
					<Link key={item.id} to={ContentURL.contentListURL({}, { categoryName: item.name, categoryId: item.id })}>
						<div dangerouslySetInnerHTML={{ __html: Format.brToTag(item.icon) }}></div>
						<span>{item.name}</span>
					</Link>
				))}
				<Link to="/lien-he">
					<span>Liên hệ</span>
				</Link>
			</div>
		);
	}
}
