import React, { Component } from "react";
import { Input, Button } from "antd";

import { Format } from "../../common/format";
import { Popup } from "../../common/component";

import { CartService } from "./productService";
import { FileService } from "../file/fileService";
import { searchBoxService } from "../frontend/searchbox";

export default class Cart extends Component {
	state = {
		cart: [],
	};

	componentDidMount() {
		searchBoxService.visible(false);
		Popup.spin.show();
		CartService.getItemList().then((res) => {
			console.log('res', res);
			if (res.itemList.length > 0) this.setState({ cart: res.itemList });
			else this.props.history.push("/");
			Popup.spin.hide();
		});
	}

	onItemQtyChange = async (item, qty) => {
		let qtyNew = parseInt(qty);
		if (isNaN(qtyNew) || qtyNew < 1) qtyNew = null;
		if (qtyNew > 0) {
			clearTimeout(this.itemQtyChangeHandle);
			this.itemQtyChangeHandle = setTimeout(async () => {
				const qtyCheckRes = await CartService.quantityCheck({ id: item.id, quantity: qty });
				item.enough = qtyCheckRes.enough;
				item.inStock = qtyCheckRes.inStock;
				if (qtyCheckRes.enough === false) {
					Popup.error({ title: "Quá số lượng hiện có là: " + qtyCheckRes.inStock });
				}
				let cart = this.state.cart;
				CartService.setCart(cart);
				this.setState({ cart });
			}, 800);
		}

		item.qty = qtyNew;

		let cart = this.state.cart;
		CartService.setCart(cart);
		this.setState({ cart });
	};

	onItemDelete = (index, id) => {
		let cart = this.state.cart;

		cart.splice(index, 1);

		CartService.deleteCart(id);

		if (cart.length > 0) this.setState({ cart });
		else this.props.history.push("/");
	};

	onCheckout = async () => {
		let isOK = true;
		const cart = this.state.cart;
		const res = await CartService.quantityCheckList();
		const { stockList } = res;

		for (let index = 0; index < cart.length; index++) {
			const item = cart[index];
			const stock = stockList.find((m) => m.id === item.id);
			if (!stock) {
				item.isDeleted = true;
				isOK = false;
				continue;
			}

			item.enough = stock.enough;
			item.inStock = stock.inStock;

			if (!stock.enough) isOK = false;
		}

		if (isOK && res.isError) isOK = false;

		if (isOK) this.props.history.push("/checkout");
		else this.setState({ cart });
	};

	render() {
		const { cart } = this.state;
		let sumPrice = 0;
		if (cart && cart.length > 0) {
			cart.forEach((item) => {
				sumPrice += item.price * item.qty;
			});
		}

		return (
			<div className="cart-page">
				<h1>Giỏ hàng</h1>
				<div className="box">
					<div className="table has-left-col">
						<ol>
							<li className="collapsing">Hình ảnh</li>
							<li>Tên</li>
							<li className="right collapsing">Giá</li>
							<li className="center">SL</li>
							<li className="right">Tổng</li>
							<li></li>
						</ol>
						{cart.map((item, index) => (
							<ul key={item.id} className={item.isDeleted ? "isDeleted" : ""}>
								<li className="left-col">
									<div className="image-1-1" style={{ width: "80px", backgroundImage: "url(" + FileService.imageURL(item.imageId, 128) + ")" }}></div>
								</li>
								<li className="name-col">
									<a>{item.name}</a>
									<div className="delete-btn sm-show">
										<Button onClick={() => this.onItemDelete(index)}>
											<i className="fal fa-trash-alt" />
										</Button>
									</div>
									{item.isDeleted && <div className="isDeleted-error">Sản phẩm không tồn tại.</div>}
								</li>
								<li className="right collapsing price-col">
									<div className={"price" + (item.isDiscount && " discount")}>{Format.number(item.price)}</div>
								</li>
								<li className="center qty-col">
									<Input className="qty" value={item.qty} onChange={({ target: { value } }) => this.onItemQtyChange(item, value)} />
									{(item.enough === false || item.onlyBuyOne) && <div className="qty-error">Tối đa: {item.inStock}</div>}
								</li>
								<li className="right collapsing total-col" data-title="Total">
									<div className={"price" + (item.isDiscount && " discount")}>{Format.number(item.price * item.qty)}</div>
								</li>
								<li className="menu-col sm-hide">
									<a onClick={() => this.onItemDelete(index, item.id)}>
										<i className="fal fa-trash-alt" />
									</a>
								</li>
							</ul>
						))}
					</div>
					{sumPrice > 0 && (
						<div className="sum-box">
							<Button type="primary" onClick={this.onCheckout} className="checkout-btn">
								<i className="fa fa-check" />
								Thanh toán
							</Button>
							<div className="total">Tổng cộng: {Format.number(sumPrice)}</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}
