import React, { Component } from "react";
import { Link } from "react-router-dom";
import Media from "react-media";
import { Row, Col, Modal, Button } from "antd";
import OwlCarousel from "react-owl-carousel2";
import * as Const from "../../common/const";
import getSlug from "speakingurl";

import { Format } from "../../common/format";
import { ProductHomeService, ProductURL } from "../product/productService";
import { searchBoxService } from "./searchbox";
import ProductGrid from "../product/component/productGrid"

export default class Home extends Component {
	state = {
		isPopup: false,
		bannerList: [],
		latestProductList: [],
		productCategoryHighlightList: []
	};

	componentDidMount() {
		searchBoxService.visible(true);
		searchBoxService.setParams({});
		this.init();
	}

	init = () => {
		ProductHomeService.init().then((res) => {
			this.setState({
				latestProductList: res.latestProductList ?? [],
			});
		});

		ProductHomeService.productCategoryHighlightGetList().then((res) => {
			this.setState({
				productCategoryHighlightList: res.productCategoryHighlightList ?? [],
			});
		});
	};


	onClosePopup = () => {
		this.setState({ isPopup: false });
	};

	render() {
		const { latestProductList, productCategoryHighlightList, bannerList } = this.state;
		const bannerOptions = {
			items: 1,
			rewind: true,
			autoplay: true,
			smartSpeed: 800,
			autoplayHoverPause: true,
		};

		const partnerOptions = {
			margin: 20,
			autoWidth: true,
			dots: false,
			nav: true,
			navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
			rewind: true,
			smartSpeed: 500,
		};

		return (
			<React.Fragment>
				{/* <section className="home-banner-main">
					<div className="home-banner-wrap">
						<div className="banner">
								<OwlCarousel options={bannerOptions}>
								<a >
									<div className="banner-home" style={{ backgroundImage: "url(/image/banner/2.jpg)" }}></div>
								</a>
								</OwlCarousel>
						</div>
					</div>
				</section> */}
				<section className="product-featured">
					<div className="container">
						<div className="product-block">
							<Row justify="space-between" align="middle">
								<Col>
									<div className="title">Sản phẩm mới nhất</div>
								</Col>
								<Col>
									<Link className="view-all" to={ProductURL.productListURL({}, { name: 'moi nhat' })}>
										<i className="fal fa-ballot" />
										Xem tất cả
									</Link>
								</Col>
							</Row>
						</div>
						<ProductGrid productList={latestProductList} />
					</div>
				</section>
				{productCategoryHighlightList.map((category) => (
					<section key={category.categoryId} className="product-featured">
						<div className="container">
							<div className="product-block">
								<Row justify="space-between" align="middle">
									<Col>
										<div className="title">{category.categoryName}</div>
									</Col>
									<Col>
										<Link className="view-all" to={ProductURL.productListURL({}, { categoryName: category.categoryName, categoryId: category.categoryId })}>
											<i className="fal fa-ballot" />
											Xem tất cả
										</Link>
									</Col>
								</Row>
							</div>
							<ProductGrid productList={category.highlightProductList} />
						</div>
					</section>
				))}
				<section className="partner">
					<div className="container">
						<OwlCarousel options={partnerOptions}>
							<div className="item">
								<img src="/image/partner/levi.png" alt="Levi" />
							</div>
							<div className="item">
								<img src="/image/partner/lee.png" alt="Lee" />
							</div>
							<div className="item">
								<img src="/image/partner/cK.png" alt="cK" />
							</div>
							<div className="item">
								<img src="/image/partner/edwin.jpg" alt="Edwin" />
							</div>
							<div className="item">
								<img src="/image/partner/guess.jpg" alt="Guess" />
							</div>
						</OwlCarousel>
					</div>
				</section>
			</React.Fragment>
		);
	}
}
