import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Select, Pagination, Breadcrumb } from "antd";
import getSlug from "speakingurl";

import { Format } from "../../common/format";
import { Popup } from "../../common/component";
import OwlCarousel from "react-owl-carousel2";

import ProductGrid from "../product/component/productGrid";
import { ProductListService, ProductURL } from "./productService";
import { FileService } from "../file/fileService";
import { searchBoxService } from "../frontend/searchbox";

export default class ProductList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			itemList: [],
			pageIndex: this.props.match.params.pageIndex,
			totalPages: 0,
			totalItems: 0,
			pageSize: 0,
			keyword: this.props.match.params.keyword,
			categoryId: this.props.match.params.categoryId,
			brandId: this.props.match.params.brandId,
			colorId: this.props.match.params.colorId,
			waist: this.props.match.params.waist,
			hipLine: this.props.match.params.hipLine,
			thigh: this.props.match.params.thigh,
			leg: this.props.match.params.leg,
			pipe: this.props.match.params.pipe,
			orderBy: this.props.match.params.orderBy ? this.props.match.params.orderBy : "latest",
			categorySider: null,
			brandList: [],
		};
	}

	getQuery = () => ({
		categoryId: this.state.categoryId,
		categoryName: this.state.categorySider.name,
		brandId: this.state.brandId,
		colorId: this.state.colorId,
		waist: this.state.waist,
		hipLine: this.state.hipLine,
		thigh: this.state.thigh,
		leg: this.state.leg,
		pipe: this.state.pipe,
		pageIndex: this.state.pageIndex,
		orderBy: this.state.orderBy,
		keyword: this.state.keyword
	});

	componentDidMount() {
		searchBoxService.visible(true);
		ProductListService.init().then((res) => { });
		this.categoryGetList();
		this.setParamSearchBox();
	}

	setParamSearchBox = () => {
		searchBoxService.setParams({
			keyword: this.state.keyword,
			categoryId: this.state.categoryId,
			brandId: this.state.brandId,
			colorId: this.state.colorId,
			waistSize: this.state.waist,
			hipLineSize: this.state.hipLine,
			thighSize: this.state.thigh,
			legSize: this.state.leg,
			pipeSize: this.state.pipe,
		});
	}

	categoryGetList = () => {
		ProductListService.categoryGetList({
			categoryId: this.state.categoryId,
		}).then((res) => {
			this.setState(
				{
					categorySider: {
						name: res.categoryName,
						childrenList: res.childrenList,
					},
					navList: res.parentList,
					brandList: res.brandList,
				},
				this.getList
			);
		});
	};

	getList = () => {
		Popup.spin.show();
		ProductListService.getList({
			keyword: this.state.keyword,
			categoryId: this.state.categoryId != 0 ? this.state.categoryId : null,
			brandId: this.state.brandId != 0 ? this.state.brandId : null,
			colorId: this.state.colorId != 0 ? this.state.colorId : null,
			waist: this.state.waist,
			hipLine: this.state.hipLine,
			thigh: this.state.thigh,
			leg: this.state.leg,
			pipe: this.state.pipe,
			orderBy: this.state.orderBy,
			pageIndex: this.state.pageIndex,
		}).then((res) => {
			this.setState({
				categoryName: res.categoryName,
				itemList: res.itemList ? res.itemList : [],
				pageIndex: res.pageIndex,
				totalPages: res.totalPages,
				totalItems: res.totalItems,
				pageSize: res.pageSize,
			});
			Popup.spin.hide();
		});
	};

	onBrandClick = (brandId) => {
		this.setState({ brandId }, this.getList);
	};

	onBrandClear = (e) => {
		this.setState({ brandId: null }, this.getList);
	};

	shouldComponentUpdate(nextProps) {
		let hasChange = false;
		if (this.props.match.params.categoryId != nextProps.match.params.categoryId) {
			this.state.categoryId = nextProps.match.params.categoryId;
			hasChange = true;
		}

		if (this.props.match.params.brandId != nextProps.match.params.brandId) {
			this.state.brandId = nextProps.match.params.brandId;
			hasChange = true;
		}

		if (this.props.match.params.colorId != nextProps.match.params.colorId) {
			this.state.colorId = nextProps.match.params.colorId;
			hasChange = true;
		}

		if (this.props.match.params.waist != nextProps.match.params.waist) {
			this.state.waist = nextProps.match.params.waist;
			hasChange = true;
		}

		if (this.props.match.params.hipLine != nextProps.match.params.hipLine) {
			this.state.hipLine = nextProps.match.params.hipLine;
			hasChange = true;
		}

		if (this.props.match.params.thigh != nextProps.match.params.thigh) {
			this.state.thigh = nextProps.match.params.thigh;
			hasChange = true;
		}

		if (this.props.match.params.leg != nextProps.match.params.leg) {
			this.state.leg = nextProps.match.params.leg;
			hasChange = true;
		}

		if (this.props.match.params.pipe != nextProps.match.params.pipe) {
			this.state.pipe = nextProps.match.params.pipe;
			hasChange = true;
		}

		if (this.props.match.params.pageIndex != nextProps.match.params.pageIndex) {
			this.state.pageIndex = nextProps.match.params.pageIndex;
			hasChange = true;
		}

		if (this.props.match.params.orderBy != nextProps.match.params.orderBy) {
			this.state.orderBy = nextProps.match.params.orderBy;
			hasChange = true;
		}

		if (this.props.match.params.keyword != nextProps.match.params.keyword) {
			this.state.keyword = nextProps.match.params.keyword;
			hasChange = true;
		}

		if (hasChange) this.categoryGetList();

		return true;
	}

	render() {
		const itemList = this.state.itemList ? this.state.itemList : [];
		const bannerOptions = {
			items: 1,
			rewind: true,
			autoplay: true,
			smartSpeed: 800,
			autoplayHoverPause: true,
		};

		return (
			<React.Fragment>
				<section className="productList-page">
					<div className="container">
						<Breadcrumb className="page-nav">
							<Breadcrumb.Item>
								<a href="/">Trang chủ</a>
							</Breadcrumb.Item>
							{this.state.navList &&
								this.state.navList.map((item) => (
									<Breadcrumb.Item key={item.id}>
										<Link to={ProductURL.productListURL({}, { categoryId: item.id, categoryName: item.name })}>{item.name}</Link>
									</Breadcrumb.Item>
								))}
						</Breadcrumb>
						<Row>
							<Col xs={0} md={4}>
								{this.state.categorySider && (
									<div className="categorySider">
										<div className="title">{this.state.categorySider.name}</div>
										<div className="list">
											{this.state.categorySider.childrenList.map((item) => (
												<Link
													key={item.id}
													className={item.id == this.state.categoryId ? "active" : ""}
													to={ProductURL.productListURL(this.getQuery(), {
														categoryId: item.id,
														categoryName: item.name,
														brandId: 0,
														brandName: "",
													})}
												>
													{item.name}
												</Link>
											))}
										</div>
									</div>
								)}
								{this.state.brandList.length > 0 && (
									<div className="brandSider">
										<div className="title">Thương hiệu</div>
										<div className="list">
											{this.state.brandList.map((item) => (
												<Link
													className={item.id == this.state.brandId ? "active" : ""}
													key={item.id}
													to={ProductURL.productListURL(this.getQuery(), { brandId: item.id, brandName: item.name })}
												>
													<span>{item.name}</span>
													<i className="fa fa-times" onClick={this.onBrandClear} />
												</Link>
											))}
										</div>
									</div>
								)}
							</Col>
							<Col xs={24} md={20}>
								{this.state.categorySider && (
									<React.Fragment>
										<Row className="productTool">
											<Col flex="auto">
												<div className="page-title">
													{this.state.categoryId == 0 && (
														<><h1>Tất cả</h1>: <em>{this.state.totalItems} kết quả</em></>
													)}
													{this.state.categoryId != 0 && (
														<><h1>{this.state.categorySider.name}</h1>: <em>{this.state.totalItems} kết quả</em></>
													)}

												</div>
											</Col>
											<Col>
												<Select
													style={{ width: "140px" }}
													value={this.state.orderBy}
													onChange={(value) => this.props.history.push(ProductURL.productListURL(this.getQuery(), { orderBy: value }))}
												>
													<Select.Option value="latest">Mới nhất</Select.Option>
													<Select.Option value="oldest">Cũ nhất</Select.Option>
												</Select>
											</Col>
											<Col style={{ marginLeft: "auto" }}>
												<Pagination
													current={this.state.pageIndex}
													total={this.state.totalItems}
													pageSize={this.state.pageSize}
													onChange={(value) => this.props.history.push(ProductURL.productListURL(this.getQuery(), { pageIndex: value }))}
												/>
											</Col>
										</Row>
										<div className="productList">
											<ProductGrid productList={itemList} />
										</div>
										{this.state.totalItems > 0 && (
											<Row className="productTool">
												<Col style={{ marginLeft: "auto" }}>
													<Pagination
														current={this.state.pageIndex}
														total={this.state.totalItems}
														pageSize={this.state.pageSize}
														onChange={(value) => this.props.history.push(ProductURL.productListURL(this.getQuery(), { pageIndex: value }))}
													/>
												</Col>
											</Row>
										)}

									</React.Fragment>
								)}
							</Col>
						</Row>
					</div>
				</section>
			</React.Fragment>
		);
	}
}
