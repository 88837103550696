export let domainName = "jadebi.com";
export let domainURL = "http://" + domainName;
export let apiURL = "https://api.jadebi.com/";

if (process.env.NODE_ENV === "development") {
	domainName = "localhost:44399";
	domainURL = "https://" + domainName;
	apiURL = "https://api.jadebi.com/";
}

export const beRoutePath = "/office";

export const languageDefault = "vi";

export const apiCode = {
	success: 1,
	validation: 2,
	error: 3,
	processing: 4,
};

export const media = {
	largeQuery: "(min-width: 992px)",
	smallQuery: "(max-width: 991px)",
};

export const waistFilterList = [
	{ value: "34-0", text: "Từ 34" },
	{ value: "34-35", text: "Từ 34 - đến 35" },
	{ value: "44-45", text: "Từ 44 - đến 45" },
	{ value: "46-47", text: "Từ 46 - đến 47" },
];

export const hipLineFilterList = [
	{ value: "54-0", text: "Từ 54" },
	{ value: "54-55", text: "Từ 54 - đến 55" },
	{ value: "56-57", text: "Từ 56 - đến 57" },
	{ value: "70-74", text: "Từ 70 - đến 74" },
];

export const thighFilterList = [
	{ value: "50-0", text: "Từ 50" },
	{ value: "50-55", text: "Từ 50 - đến 55" },
	{ value: "56-57", text: "Từ 56 - đến 57" },
	{ value: "60-62", text: "Từ 60 - đến 62" },
];

export const legFilterList = [
	{ value: "70-0", text: "Từ 70" },
	{ value: "70-80", text: "Từ 70 - đến 80" },
	{ value: "80-90", text: "Từ 80 - đến 90" },
	{ value: "90-100", text: "Từ 90 - đến 100" },
];

export const pipeFilterList = [
	{ value: "40-0", text: "Từ 40" },
	{ value: "40-50", text: "Từ 40 - đến 50" },
	{ value: "50-60", text: "Từ 50 - đến 60" },
	{ value: "70-80", text: "Từ 70 - đến 80" },
];

export const pageCategory = 4;
export const pageMainMenu = [5];
export const pageSiderMenu = [5];
