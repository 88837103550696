import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Affix } from "antd";
import Media from "react-media";

import * as Const from "../../common/const";

import { CurrentUserService } from "../user/currentUserService";
import { LoginService } from "../user/userService";
import { CartService } from "../product/productService";

import MenuMain from "./menuMain";
import Searchbox from "./searchbox";
import SiderBase, { siderService } from "../../common/siderBase";
import Sider from "./sider";

export default class Header extends Component {
	constructor(props) {
		super(props);

		const cart = CartService.getCart();
		let cartQty = 0;
		cart.forEach((item) => (cartQty += item.qty));

		this.state = {
			userCurrent: CurrentUserService.getUser(),
			mainbarAffix: "",
			cart: cart,
			cartQty: cartQty,
		};
	}

	componentDidMount() {
		this.userCurrentSubscription = CurrentUserService.getObservable().subscribe((user) => {
			this.setState({ userCurrent: user });
		});

		this.cartSubscription = CartService.getObservable().subscribe((cart) => {
			let cartQty = 0;
			cart.forEach((item) => (cartQty += item.qty));
			this.setState({ cart: cart, cartQty });
		});
	}

	componentWillUnmount() {
		this.userCurrentSubscription.unsubscribe();
	}

	mainbarAffixChange = (affixed) => this.setState({ mainbarAffix: affixed ? "affix" : "un-affix" });

	onLogout = () => {
		LoginService.logoutSubmit();
		window.location = "/";
	};

	render() {
		const { mainbarAffix } = this.state;

		return (
			<header className="header">
			<Media
					query={Const.media.largeQuery}
					render={() => (
						<div className="topbar">
							<div className="container">
								<div className="left">
									<div className="header-contact">
										<Link to="/">
											<i className="fal fa-phone-alt" />
											<span>093.358.9015</span>
										</Link>
										<a href="https://www.instagram.com/jadebisaigon/">
											<i className="fab fa-instagram"></i>
											<span>Instagram</span>
										</a>
									</div>
								</div>
								<div className="right">
									<div className="header-topbar-menu">
										<Link to="/">
											<i className="fal fa-file-edit"></i>
											<span>Đăng ký</span>
										</Link>
										<Link to="/login">
											<i className="fal fa-user"></i>
											<span>Đăng nhập</span>
										</Link>
									</div>
								</div>
							</div>
						</div>
					)}
				/>
				<Affix onChange={this.mainbarAffixChange} offsetTop={-100} className="ant-affix-wrapper">
					<div className={"mainbar " + mainbarAffix}>
						<div className="container">
							<div className="left">
								<Media
									query={Const.media.smallQuery}
									render={() => (
										<a
											className="sider-button"
											onClick={() => {
												siderService.visible();
											}}
										>
											<i className="fal fa-bars"></i>
										</a>
									)}
								/>
							</div>
							<div className="center">
								<Link to="/" className="site-logo">
									<img src="/image/logo.jpg" />
								</Link>
							</div>
							<div className="right">
								<Media query={Const.media.largeQuery} render={() => <MenuMain />} />
								<div className="header-mainbar-menu">
									{this.state.cartQty > 0 ? (
										<Link to="/cart" className="mainbar-button cart">
											
												<i className="fad fa-shopping-bag"></i>
												<div className="number-bag">{this.state.cartQty}</div>
										</Link>
									) : (
										<a>
											<i className="fad fa-shopping-bag"></i>
										</a>
									)}
								</div>
							</div>
						</div>
					</div>
				</Affix>
				<Searchbox/>
				<SiderBase colapse>
					<Sider />
				</SiderBase>
			</header>
		);
	}
}
