import { Subject } from "rxjs";
import getSlug from "speakingurl";

import * as Service from "../../common/serviceHelper";

function CategoryParseTreeLoop(categoryList, parentId) {
	let childList = [];
	for (var i = 0; i < categoryList.length; i++) {
		const item = categoryList[i];
		if (item.parentId === parentId) {
			const children = CategoryParseTreeLoop(categoryList, item.id);
			childList.push({
				title: item.name,
				value: item.id,
				children,
			});
		}
	}
	return childList;
}

export const CategoryService = {
	parseTree: (categoryList) => {
		if (!categoryList) return null;
		categoryList.sort((a, b) => a.orderNumber - b.orderNumber);
		return CategoryParseTreeLoop(categoryList, 1);
	},
	submit: async (param) => await Service.post("api/product/categorypost/create", param),
};

export const SiderService = {
	categoryGetList: async () => await Service.get("api/product/sider/categoryGetList"),
};

export const MenuService = {
	categoryTree: async (param) => await Service.get("api/product/home/categoryTree", param),
};

export const ProductHomeService = {
	init: async (param) => await Service.get("api/product/home/init", param),
	categoryTree: async (param) => await Service.get("api/product/home/CategoryTree", param),
	featuredGetList: async (param) => await Service.get("api/product/home/featuredGetList", param),
	productCategoryHighlightGetList: async (param) => await Service.get("api/product/home/productCategoryHighlightGetList", param),
};

export const ProductDetailService = {
	init: async (param) => await Service.get("api/product/detail/init", param),
	categoryGetList: async (param) => await Service.get("api/product/detail/categoryGetList", param),
};

export const ProductListService = {
	init: async () => await Service.get("api/product/list/init"),
	getList: async (param) => await Service.get("api/product/list/getList", param),
	categoryGetList: async (param) => await Service.get("api/product/list/categoryGetList", param),
};

export const ProductManageService = {
	init: async () => await Service.get("api/product/manage/init"),
	getList: async (param) => await Service.get("api/product/manage/getList", param),
};

export const ProductPostService = {
	init: async (param) => await Service.get("api/product/post/init", param),
	submit: async (param) => await Service.post("api/product/post/submit", param),
	delete: async (param) => await Service.post("api/product/post/delete", param),
};

export const SearchBoxService = {
	init: async (param) => await Service.get("api/product/searchBox/init", param),
};

const cartSubject = new Subject();
let cartData = null;
export const CartService = {
	getItemList: async () => {
		CartService.getCart();

		if (cartData.length == 0) return [];

		let idList = [];
		cartData.forEach((item) => idList.push(item.id));
		const res = await Service.get("api/product/cart/getItemList", { idList: idList });
		if (res.success) {
			res.itemList.forEach((item) => {
				const cartItem = cartData.find((m, index) => {
					m.orderNumber = index;
					return m.id === item.id;
				});
				item.qty = cartItem.qty;
				item.orderNumber = cartItem.orderNumber;
			});
			res.itemList.sort((a, b) => a.orderNumber - b.orderNumber);
			CartService.setCart(res.itemList.map((item) => ({ id: item.id, qty: item.qty })));
			return res;
		} else return [];
	},
	getCart: () => {
		if (cartData == null) {
			let cartString = localStorage.getItem("shoppingCart");
			cartData = cartString ? JSON.parse(cartString) : [];
		}

		return cartData;
	},
	addCart: async (item) => {
		CartService.getCart();

		const itemCart = cartData.find((m) => m.id === item.id);

		const qtyCheck = itemCart ? itemCart.qty + item.qty : item.qty;
		const quantityCheckRes = await CartService.quantityCheck({ id: item.id, quantity: qtyCheck });
		if (quantityCheckRes.enough) {
			if (itemCart) itemCart.qty += item.qty;
			else cartData.push({ id: item.id, qty: item.qty });

			localStorage.setItem("shoppingCart", JSON.stringify(cartData));
			cartSubject.next(cartData);
		}
		return quantityCheckRes;
	},
	setCart: (cart) => {
		cartData = cart;

		localStorage.setItem("shoppingCart", JSON.stringify(cartData));

		cartSubject.next(cartData);
	},
	deleteCart: (id) => {
		CartService.getCart();
		cartData = cartData.filter((item) => item.id !== id);
		localStorage.setItem("shoppingCart", JSON.stringify(cartData));
		cartSubject.next(cartData);
	},
	clearCart: () => CartService.setCart([]),
	getObservable: () => cartSubject.asObservable(),
	quantityCheck: async (param) => await Service.get("api/product/cart/QuantityCheck", param),
};

export const CheckoutService = {
	init: async () => await Service.get("api/product/checkout/init"),
	submit: async (param) => await Service.post("api/product/checkout/submit", param),
};

export const OrdersManageService = {
	init: async () => await Service.get("api/product/ordersManage/init"),
	getList: async (param) => await Service.get("api/product/ordersManage/getList", param),
	getDetail: async (param) => await Service.get("api/product/ordersManage/getDetail", param),
	update: async (param) => await Service.post("api/product/ordersManage/update", param),
	cancel: async (param) => await Service.post("api/product/ordersManage/cancel", param),
};

export const ProductBrandManageServices = {
	getList: async (param) => await Service.get("api/product/brandManage/getList", param),
};

export const ProductBrandPostServices = {
	init: async (param) => await Service.get("api/product/brandPost/init", param),
	submit: async (param) => await Service.post("api/product/brandPost/submit", param),
	delete: async (param) => await Service.post("api/product/brandPost/delete", param),
};

export const ProductColorManageServices = {
	getList: async (param) => await Service.get("api/product/colorManage/getList", param),
};

export const ProductColorPostServices = {
	init: async (param) => await Service.get("api/product/colorPost/init", param),
	submit: async (param) => await Service.post("api/product/colorPost/submit", param),
	delete: async (param) => await Service.post("api/product/colorPost/delete", param),
};

export const ProductCategoryManageService = {
	getList: async (param) => await Service.get("api/product/categoryManage/getList", param),
};

export const ProductCategoryPostService = {
	init: async (param) => await Service.get("api/product/categoryPost/Init", param),
	getOrderList: async (param) => await Service.get("api/product/categoryPost/GetOrderList", param),
	submit: async (param) => await Service.post("api/product/categoryPost/submit", param),
	delete: async (param) => await Service.post("api/product/categoryPost/delete", param),
};

export const ProductURL = {
	productListURL: (query, option) => {
		if (option.keyword != null) query.keyword = option.keyword;
		if (option.name != null) query.name = option.name;
		if (option.categoryName != null) query.categoryName = option.categoryName;
		if (option.brandName != null) query.brandName = option.brandName;
		if (option.colorName != null) query.colorName = option.colorName;

		if (option.categoryId != null) query.categoryId = option.categoryId;
		if (option.brandId != null) query.brandId = option.brandId;
		if (option.colorId != null) query.colorId = option.colorId;
		if (option.waist != null) query.waist = option.waist;
		if (option.hipLine != null) query.hipLine = option.hipLine;
		if (option.thigh != null) query.thigh = option.thigh;
		if (option.leg != null) query.leg = option.leg;
		if (option.pipe != null) query.pipe = option.pipe;
		if (option.pageIndex != null) query.pageIndex = option.pageIndex;
		if (option.orderBy != null) query.orderBy = option.orderBy;

		let text = "";
		if (query.categoryName) text += (text ? "-" : "") + query.categoryName;
		if (query.brandName) text += (text ? "-" : "") + query.brandName;
		if (query.colorName) text += (text ? "-" : "") + query.colorName;
		if (query.waist && query.waist > 0) text += (text ? "-" : "") + query.waist;
		if (query.hipLine && query.hipLine > 0) text += (text ? "-" : "") + query.hipLine;
		if (query.thigh && query.thigh > 0) text += (text ? "-" : "") + query.thigh;
		if (query.leg && query.leg > 0) text += (text ? "-" : "") + query.leg;
		if (query.pipe && query.pipe > 0) text += (text ? "-" : "") + query.pipe;
		if (query.name) text += (text ? "-" : "") + query.name;

		if (text == "") text = "tat-ca";
		let url = "/san-pham/" + getSlug(text);

		url += query.categoryId ? "/" + query.categoryId : "/0";
		url += query.brandId ? "/" + query.brandId : "/0";
		url += query.colorId ? "/" + query.colorId : "/0";
		url += query.waist ? "/" + query.waist : "/0";
		url += query.hipLine ? "/" + query.hipLine : "/0";
		url += query.thigh ? "/" + query.thigh : "/0";
		url += query.leg ? "/" + query.leg : "/0";
		url += query.pipe ? "/" + query.pipe : "/0";
		url += query.pageIndex ? "/" + query.pageIndex : "/1";
		url += query.orderBy ? "/" + query.orderBy : "/latest";
		url += query.keyword ? "/" + encodeURIComponent(query.keyword) : "/";

		return url;
	},
	productDetailURL: (query, option) => {
		if (option.name != null) query.name = option.name;
		if (option.id != null) query.id = option.id;

		let text = "";
		if (query.name) text += (text ? "" : "") + query.name;

		let url = "/chi-tiet-san-pham/" + getSlug(text);

		url += query.id ? "/" + query.id : "/0";

		return url;
	},
};
