import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";

import * as Const from "../../common/const";
import { MenuService, HomeContentService, ContentURL } from "../content/contentService";
import { MenuService as ProductMenuService, ProductURL } from "../product/productService"

export default class Footer extends Component {
	state = {
		categoryList: [],
		productMenuList: [],
		contactInfo: {},
	};

	componentDidMount() {
		MenuService.categoryGetList({ parentId: Const.pageCategory, idList: Const.pageMainMenu }).then((res) => {
			if (!res.success) return;

			this.setState({ categoryList: res.itemList ? res.itemList : [] });
		});

		HomeContentService.getList({ idList: [1], hasDescription: true }).then((res) => {
			if (!res.success) return;

			this.setState({
				contactInfo: res.itemList[0],
			});
		});

		ProductMenuService.categoryTree({level: 1}).then((res) => {
			if (!res.success) return;

			this.setState({
				productMenuList: res.itemList
			});
		});
	}

	render() {
		const { categoryList, contactInfo, productMenuList } = this.state;

		return (
			<footer className="footer">
				<div className="container">
					<Row>
						<Col xs={24} sm={24} md={{ span: 12, offset: 2 }} lg={{ span: 7, offset: 0 }}>
							<div className="footer-menu">
								<img src="/image/logo.jpg" className="footer-logo" />
								{contactInfo && (
									<div dangerouslySetInnerHTML={{ __html: contactInfo.description }} />
								)}
							</div>
						</Col>
						<Col xs={24} sm={24} md={{ span: 10, offset: 0 }} lg={{ span: 6, offset: 0 }}>
							<div className="footer-menu">
								<div className="title">JADEBI</div>
								{categoryList && 
									categoryList.map((item) => (
									<Link key={item.id} to={ContentURL.contentListURL({}, { categoryName: item.name, categoryId: item.id })}>
										<span>{item.name}</span>
									</Link>
									))
								}
								{productMenuList && 
									productMenuList.map((item) => (
									<Link key={item.id} to={ProductURL.productListURL({}, { categoryName: item.name, categoryId: item.id })}>
										<span>{item.name}</span>
									</Link>
									))
								}
								<Link to="/lien-he">
										<span>Liên hệ</span>
								</Link>
							</div>
						</Col>
						<Col xs={12} sm={12} md={{ span: 12, offset: 2 }} lg={{ span: 6, offset: 0 }}>
							<div className="footer-menu">
								<div className="title">Luôn kết nối</div>
								<a href="https://www.facebook.com/jadebisaigon">
									<i className="fab fa-facebook-f"></i>
									<span>Facebook</span>
								</a>
								<a href="https://www.instagram.com/jadebisaigon/">
									<i className="fab fa-instagram"></i>
									<span>Instagram</span>
								</a>
							</div>
						</Col>
						<Col xs={12} sm={12} md={{ span: 10, offset: 0 }} lg={{ span: 5, offset: 0 }}>
							<div className="footer-menu">
								<div className="title">Thanh toán</div>
								<a href="">
									<i className="fab fa-cc-mastercard"></i>
									<span>Master Card</span>
								</a>
								<a href="">
									<i className="fab fa-cc-visa"></i>
									<span>Visa Card</span>
								</a>
								<a href="">
									<i className="fab fa-cc-paypal"></i>
									<span>Paypal</span>
								</a>
								<a href="">
									<i className="fas fa-credit-card-front"></i>
									<span>Thẻ ATM</span>
								</a>
							</div>
						</Col>
					</Row>
				</div>
				<div className="copyright">Copyright by jadebi.com. All rights reserved.</div>
			</footer>
		);
	}
}
