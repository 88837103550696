import React, { Component } from "react";
import { Row, Col, Input, Button } from "antd";

import { Popup, Msg } from "../../common/component";

import { ProductColorPostServices } from "./productService";

export default class ColorPost extends Component {
	state = {
		isAdd: !this.props.itemId,
		id: this.props.itemId,
		name: null
	};

	componentDidMount() {
		this.init();
	}

	init = () => {
		if (!this.state.id) return;

		ProductColorPostServices.init({ id: this.state.id }).then((res) => {
			if (!res.success || !res.item) return;

			this.setState({
				name: res.item.name,
			});
		});
	};

	onSubmit = async () => {
		Popup.spin.show();
		const res = await ProductColorPostServices.submit({
			id: this.state.id,
			name: this.state.name,
		});

		Popup.spin.hide();
		if (res.success) {
			Popup.info({ autoClose: true });
			this.props.onOk();
		}

		this.setState({ msgs: res.msgs });
	};

	onSubmitAndContinues = async () => {
		let updateState = {};
		Popup.spin.show();
		const res = await ProductColorPostServices.submit({
			id: this.state.id,
			name: this.state.name
		});

		updateState.msgs = res.msgs;
		Popup.spin.hide();
		if (res.success) {
			Popup.info({ autoClose: true });
			this.props.onItemOkAndContinue();
			this.init();
			updateState.name = "";
		}

		this.setState(updateState);
	};

	render() {
		return (
			<div className="form">
				<h1>{this.state.isAdd ? "Thêm" : "Sửa"} màu sắc</h1>
				<Row>
					<Col>
						<label>Màu</label>
						<Input value={this.state.name} onChange={({ target: { value } }) => this.setState({ name: value })} autoFocus />
						<Msg target="name" msgs={this.state.msgs} className="errorMsg" />
					</Col>
				</Row>
				<Row>
					<Col>
						<Button type="primary" onClick={this.onSubmit}>
							Đồng ý
						</Button>
						<Button type="default" onClick={this.props.onCancel}>
							Đóng
						</Button>
						{this.state.isAdd ? (
							<Button type="primary" onClick={this.onSubmitAndContinues} style={{ float: "right" }}>
								Đồng ý & Tiếp tục
							</Button>
						) : null}
					</Col>
				</Row>
			</div>
		);
	}
}
