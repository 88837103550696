import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";

import { FileService } from "../../file/fileService";
import * as Const from "../../../common/const";
import { Format } from "../../../common/format";
import getSlug from "speakingurl";
import { ProductURL } from "../productService";

export default class ProductGrid extends Component {
   constructor(props){
      super(props);
   }
	render() {
      return (
         <div className="product-grid">
            <Row>
               {this.props.productList.map((item) => (
                  <Col key={item.id} xs={24} sm={12} md={8} lg={6}>
                     <Link
                        className="item"
                        to={ProductURL.productDetailURL({}, { id: item.id, name: item.name })}>
                        <span className="image">
                           <img
                              src={FileService.imageURL(item.imageId, 1024)}
                              alt={item.name}
                           />
                        </span>
                        <div className="info">
                           <span className="name">{item.name}</span>
                           <div className="more">
                              <div>
                                 <span className="brand">
                                    <em>Hiệu:</em> {item.brandName}
                                 </span>
                              </div>
                              <div>
                                 <span className="color">
                                    <em>Màu:</em> {item.color}
                                 </span>
                              </div>
                              {item.waistText && (
                                 <div>
                                    <span>
                                       {" "}
                                       <em>Eo:</em> {item.waistText}
                                    </span>
                                 </div>
                              )}
                              {item.hipLineText && (
                                 <div>
                                    <span>
                                       <em>Mông:</em> {item.hipLineText}
                                    </span>
                                 </div>
                              )}
                              {item.thighText && (
                                 <div>
                                    <span>
                                       <em>Đùi:</em> {item.thighText}
                                    </span>
                                 </div>
                              )}
                              {item.legText && (
                                 <div>
                                    <span>
                                       <em>Dài quần:</em> {item.legText}
                                    </span>
                                 </div>
                              )}
                              {item.pipeText && (
                                 <div>
                                    <span>
                                       <em>Ống:</em> {item.pipeText}
                                    </span>
                                 </div>
                              )}
                           </div>
                        </div>
                        <div className="item-footer">
                           <div className="add-cart">
                              <div className="cart">
                                 <i className="fal fa-cart-plus"></i>
                              </div>
                           </div>
                           <span className="price">
                              {item.priceSource && (
                                 <span className="saleoff">
                                    <span className="price-origin">
                                       {Format.number(item.priceSource)}
                                    </span>
                                    <span className="saleoff-percent">
                                       -{item.saleOffPercent}%
                                    </span>
                                 </span>
                              )}
                              <div>
                                 <span className="unit">đ</span>
                                 <span className="money">
                                    {Format.number(item.price)}
                                 </span>
                              </div>
                           </span>
                        </div>
                     </Link>
                  </Col>
               ))}
            </Row>
         </div>
      )
	}
}
