import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Input, Select, Button, Row, Col } from 'antd';
import { Subject } from "rxjs";

import * as Const from "../../common/const";
import { InputNumber } from "../../common/component";
import { MinMaxToValue, MinMaxToText } from "../../common/format";
import { SearchBoxService, ProductURL } from "../product/productService";

const subject = new Subject();
export const searchBoxService = {
	setParams: (params) => subject.next({ params: params }),
	visible: (isVisible) => subject.next({ isVisible: isVisible }),
	getObservable: () => subject.asObservable(),
};

class SearchBox extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isVisible: false,
			isDefault: true,
			keyword: null,
			categoryList: [],
			brandList: [],
			colorList: [],
			waistFilterList: Const.waistFilterList,
			hipLineFilterList: Const.hipLineFilterList,
			thighFilterList: Const.thighFilterList,
			legFilterList: Const.legFilterList,
			pipeFilterList: Const.pipeFilterList
		};
	}

	setParam = (params) => {
		if (!params) {
			this.setState({
				keyword: null,
				categoryId: undefined,
				brandId: undefined,
				colorId: undefined,
				waistSize: null,
				hipLineSize: null,
				thighSize: null,
				legSize: null,
				pipeSize: null
			});
			return;
		}

		const categoryId = parseInt(params.categoryId);
		const brandId = parseInt(params.brandId);
		const colorId = parseInt(params.colorId);

		let waistText;
		if (params.waist && params.waist != 0) {
			const waistFilter = this.state.waistFilterList.find((item) => item.value == params.waist);
			if (waistFilter) waistText = waistFilter.text;
			else {
				const waistParse = params.waist.split("-");
				waistText = MinMaxToText(waistParse[0], waistParse[1]);
			}
		}

		let hipLineText;
		if (params.hipLine && params.hipLine != 0) {
			const hipLineFilter = this.state.hipLineFilterList.find((item) => item.value == params.hipLine);
			if (hipLineFilter) hipLineText = hipLineFilter.text;
			else {
				const hipLineParse = params.hipLine.split("-");
				hipLineText = MinMaxToText(hipLineParse[0], hipLineParse[1]);
			}
		}

		let thighText;
		if (params.thigh && params.thigh != 0) {
			const thighFilter = this.state.thighFilterList.find((item) => item.value == params.thigh);
			if (thighFilter) thighText = thighFilter.text;
			else {
				const thighParse = params.thigh.split("-");
				thighText = MinMaxToText(thighParse[0], thighParse[1]);
			}
		}

		let legText;
		if (params.leg && params.leg != 0) {
			const legFilter = this.state.legFilterList.find((item) => item.value == params.leg);
			if (legFilter) legText = legFilter.text;
			else {
				const legParse = params.leg.split("-");
				legText = MinMaxToText(legParse[0], legParse[1]);
			}
		}

		let pipeText;
		if (params.pipe && params.pipe != 0) {
			const pipeFilter = this.state.pipeFilterList.find((item) => item.value == params.pipe);
			if (pipeFilter) pipeText = pipeFilter.text;
			else {
				const pipeParse = params.leg.split("-");
				pipeText = MinMaxToText(pipeParse[0], pipeParse[1]);
			}
		}

		this.setState({
			keyword: params.keyword,
			categoryId: categoryId > 0 ? categoryId : undefined,
			brandId: brandId > 0 ? brandId : undefined,
			colorId: colorId > 0 ? colorId : undefined,
			waist: waistText,
			hipLine: hipLineText,
			thigh: thighText,
			leg: legText,
			pipe: pipeText
		});
	}

	componentDidMount() {
		this.searchBoxSubscription = searchBoxService.getObservable().subscribe((option) => {
			if (typeof option.isVisible !== "undefined") this.setState({ isVisible: option.isVisible });

			if (option.params) {
				this.setParam(option.params);
			}
		});

		SearchBoxService.init().then((res) => {
			if (!res.success) return;

			this.setState({
				categoryList: res.categoryList ?? [],
				brandList: res.brandList ?? [],
				colorList: res.colorList ?? [],
			});
		});
	}

	componentWillUnmount() {
		this.searchBoxSubscription.unsubscribe();
	}

	onChangeWaist = (value) => {
		if (!value) {
			console.log('this.state.waistFrom', this.state.waistFrom, this.state.waistTo);
			let waistText = MinMaxToText(this.state.waistFrom, this.state.waistTo);
			if (waistText) {
				this.setState({
					waist: waistText,
					waistValue: MinMaxToValue(this.state.waistFrom, this.state.waistTo),
				});
			} else {
				this.setState({ waist: null, waistValue: null });
			}
		} else {
			this.setState({ waist: value, waistValue: value });
		}
	}

	onChangeHipLine = (value) => {
		if (!value) {
			let hipLineText = MinMaxToText(this.state.hipLineFrom, this.state.hipLineTo);
			if (hipLineText) {
				this.setState({
					hipLine: hipLineText,
					hipLineValue: MinMaxToValue(this.state.hipLineFrom, this.state.hipLineTo),
				});
			} else {
				this.setState({ hipLine: null, hipLineValue: null });
			}
		} else {
			this.setState({ hipLine: value, hipLineValue: value });
		}
	}

	onChangeHipLine = (value) => {
		if (!value) {
			let hipLineText = MinMaxToText(this.state.hipLineFrom, this.state.hipLineTo);
			if (hipLineText) {
				this.setState({
					hipLine: hipLineText,
					hipLineValue: MinMaxToValue(this.state.hipLineFrom, this.state.hipLineTo),
				});
			} else {
				this.setState({ hipLine: null, hipLineValue: null });
			}
		} else {
			this.setState({ hipLine: value, hipLineValue: value });
		}
	}

	onChangeThigh = (value) => {
		if (!value) {
			let thighText = MinMaxToText(this.state.thighFrom, this.state.thighTo);
			if (thighText) {
				this.setState({
					thigh: thighText,
					thighValue: MinMaxToValue(this.state.thighFrom, this.state.thighTo),
				});
			} else {
				this.setState({ thigh: null, thighValue: null });
			}
		} else {
			this.setState({ thigh: value, thighValue: value });
		}
	}

	onChangeLeg = (value) => {
		if (!value) {
			let legText = MinMaxToText(this.state.legFrom, this.state.legTo);
			if (legText) {
				this.setState({
					leg: legText,
					legValue: MinMaxToValue(this.state.legFrom, this.state.legTo),
				});
			} else {
				this.setState({ leg: null, legValue: null });
			}
		} else {
			this.setState({ leg: value, legValue: value });
		}
	}

	onChangePipe = (value) => {
		if (!value) {
			let pipeText = MinMaxToText(this.state.pipeFrom, this.state.pipeTo);
			if (pipeText) {
				this.setState({
					pipe: pipeText,
					pipeValue: MinMaxToValue(this.state.pipeFrom, this.state.pipeTo),
				});
			} else {
				this.setState({ pipe: null, pipeValue: null });
			}
		} else {
			this.setState({ pipe: value, pipeValue: value });
		}
	}

	onSearch = () => {
		let name = "";
		if (this.state.categoryId) name += (name ? "-" : "") + this.state.categoryList.find((item) => item.id == this.state.categoryId).name;
		if (this.state.brandId) name += (name ? "-" : "") + this.state.brandList.find((item) => item.id == this.state.brandId).name;
		if (this.state.colorId) name += (name ? "-" : "") + this.state.colorList.find((item) => item.id == this.state.colorId).name;

		this.props.history.push(
			ProductURL.productListURL(
				{},
				{
					keyword: this.state.keyword,
					name: name,
					categoryId: this.state.categoryId,
					brandId: this.state.brandId,
					colorId: this.state.colorId,
					waist: this.state.waistValue,
					hipLine: this.state.hipLineValue,
					thigh: this.state.thighValue,
					leg: this.state.legValue,
					pipe: this.state.pipeValue
				}
			)
		);
	};

	changeWaySearch = () => {
		this.setState({
			isDefault: !this.state.isDefault
		});
	}

	render() {
		if (!this.state.isVisible) return null;
		const { categoryList, brandList, colorList, waistFilterList, hipLineFilterList, thighFilterList, legFilterList, pipeFilterList } = this.state;

		return (
			<div className="searchbox">
				<div className="container">
					{this.state.isDefault ?
						<div className="searchbox-wrap-simple">
							<Row>
								<Col xs={24}>
									<Input.Search
										placeholder="Nhập tên sản phẩm cần tìm ..."
										value={this.state.keyword}
										onChange={(event) => {
											this.setState({ keyword: event.target.value });
										}}
										onSearch={(value) => this.setState({ keyword: value }, () => this.onSearch())}
										enterButton
										allowClear
									/>
								</Col>
								<Col xs={24}>
									<div style={{ float: 'right', cursor: 'pointer' }} onClick={this.changeWaySearch}>Tìm kiếm nâng cao</div>
								</Col>
							</Row>
						</div> :
						<div className="searchbox-wrap">
							<Row>
								<Col xs={24}>
									<Input.Search
										placeholder="Nhập tên sản phẩm cần tìm ..."
										value={this.state.keyword}
										onChange={(event) => {
											this.setState({ keyword: event.target.value });
										}}
										onSearch={(value) => this.setState({ keyword: value }, () => this.onSearch())}
										enterButton
									/>
								</Col>
								<Col xs={12}>
									<Select
										dropdownClassName="searchbox-select"
										placeholder="Chọn loại"
										style={{ width: "100%" }}
										allowClear
										showSearch
										filterOption={(inputValue, option) => option.children.toString().toLowerCase().includes(inputValue.toLowerCase())}
										value={this.state.categoryId}
										onChange={(value) => this.setState({ categoryId: value })}
									>
										{categoryList.map((item) => (
											<Select.Option key={item.id} value={item.id}>
												{item.name}
											</Select.Option>
										))}
									</Select>
								</Col>
								<Col xs={12}>
									<Select
										dropdownClassName="searchbox-select"
										placeholder="Chọn hãng"
										style={{ width: "100%" }}
										allowClear
										showSearch
										filterOption={(inputValue, option) => option.children.toString().toLowerCase().includes(inputValue.toLowerCase())}
										value={this.state.brandId}
										onChange={(value) => this.setState({ brandId: value })}
									>
										{brandList.map((item) => (
											<Select.Option key={item.id} value={item.id}>
												{item.name}
											</Select.Option>
										))}
									</Select>
								</Col>
								<Col xs={12}>
									<Select
										dropdownClassName="searchbox-select"
										placeholder="Chọn màu"
										style={{ width: "100%" }}
										allowClear
										showSearch
										filterOption={(inputValue, option) => option.children.toString().toLowerCase().includes(inputValue.toLowerCase())}
										value={this.state.colorId}
										onChange={(value) => this.setState({ colorId: value })}
									>
										{colorList.map((item) => (
											<Select.Option key={item.id} value={item.id}>
												{item.name}
											</Select.Option>
										))}
									</Select>
								</Col>
								<Col xs={12}>
									<Select
										dropdownClassName="searchbox-select"
										placeholder="Số đo eo"
										style={{ width: "100%" }}
										allowClear
										value={this.state.waist}
										onChange={this.onChangeWaist}
										dropdownRender={(menu) => (
											<div>
												<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
													<Input
														placeholder="Từ"
														style={{ flex: "auto" }}
														onChange={(e) => this.setState({ waistFrom: e.target.value }, this.onChangeWaist)}
													/>
													<span style={{ padding: 5 }}> - </span>
													<Input
														placeholder="Đến"
														style={{ flex: "auto" }}
														onChange={(e) => this.setState({ waistTo: e.target.value }, this.onChangeWaist)}
													/>
												</div>
												{menu}
											</div>
										)}
									>
										{waistFilterList.map((item, index) => (
											<Select.Option key={index} value={item.value}>
												{item.text}
											</Select.Option>
										))}
									</Select>
								</Col>
								<Col xs={12}>
									<Select
										dropdownClassName="searchbox-select"
										placeholder="Số đo mông"
										style={{ width: "100%" }}
										allowClear
										value={this.state.hipLine}
										onChange={this.onChangeHipLine}
										dropdownRender={(menu) => (
											<div>
												<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
													<Input
														placeholder="Từ"
														style={{ flex: "auto" }}
														onChange={(e) => this.setState({ hipLineFrom: e.target.value }, this.onChangeHipLine)}
													/>
													<span style={{ padding: 5 }}> - </span>
													<Input
														placeholder="Đến"
														style={{ flex: "auto" }}
														onChange={(e) => this.setState({ hipLineTo: e.target.value }, this.onChangeHipLine)}
													/>
												</div>
												{menu}
											</div>
										)}
									>
										{hipLineFilterList.map((item, index) => (
											<Select.Option key={index} value={item.value}>
												{item.text}
											</Select.Option>
										))}
									</Select>
								</Col>
								<Col xs={12}>
									<Select
										dropdownClassName="searchbox-select"
										placeholder="Số đo đùi"
										style={{ width: "100%" }}
										allowClear
										value={this.state.thigh}
										onChange={this.onChangeThigh}
										dropdownRender={(menu) => (
											<div>
												<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
													<Input
														placeholder="Từ"
														style={{ flex: "auto" }}
														onChange={(e) => this.setState({ thighFrom: e.target.value }, this.onChangeThigh)}
													/>
													<span style={{ padding: 5 }}> - </span>
													<Input
														placeholder="Đến"
														style={{ flex: "auto" }}
														onChange={(e) => this.setState({ thighTo: e.target.value }, this.onChangeThigh)}
													/>
												</div>
												{menu}
											</div>
										)}
									>
										{thighFilterList.map((item, index) => (
											<Select.Option key={index} value={item.value}>
												{item.text}
											</Select.Option>
										))}
									</Select>
								</Col>
								<Col xs={12}>
									<Select
										dropdownClassName="searchbox-select"
										placeholder="Dài quần"
										style={{ width: "100%" }}
										allowClear
										value={this.state.leg}
										onChange={this.onChangeLeg}
										dropdownRender={(menu) => (
											<div>
												<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
													<Input
														placeholder="Từ"
														style={{ flex: "auto" }}
														onChange={(e) => this.setState({ legFrom: e.target.value }, this.onChangeLeg)}
													/>
													<span style={{ padding: 5 }}> - </span>
													<Input
														placeholder="Đến"
														style={{ flex: "auto" }}
														onChange={(e) => this.setState({ legTo: e.target.value }, this.onChangeLeg)}
													/>
												</div>
												{menu}
											</div>
										)}
									>
										{legFilterList.map((item, index) => (
											<Select.Option key={index} value={item.value}>
												{item.text}
											</Select.Option>
										))}
									</Select>
								</Col>
								<Col xs={12}>
									<Select
										dropdownClassName="searchbox-select"
										placeholder="Ống rộng"
										style={{ width: "100%" }}
										allowClear
										value={this.state.pipe}
										onChange={this.onChangePipe}
										dropdownRender={(menu) => (
											<div>
												<div style={{ display: "flex", flexWrap: "nowrap", padding: 8 }}>
													<Input
														placeholder="Từ"
														style={{ flex: "auto" }}
														onChange={(e) => this.setState({ pipeFrom: e.target.value }, this.onChangePipe)}
													/>
													<span style={{ padding: 5 }}> - </span>
													<Input
														placeholder="Đến"
														style={{ flex: "auto" }}
														onChange={(e) => this.setState({ pipeTo: e.target.value }, this.onChangePipe)}
													/>
												</div>
												{menu}
											</div>
										)}
									>
										{pipeFilterList.map((item, index) => (
											<Select.Option key={index} value={item.value}>
												{item.text}
											</Select.Option>
										))}
									</Select>
								</Col>
								<Col xs={24}>
									<Button type="primary" block onClick={this.onSearch} className="button-search">
										tìm kiếm
									</Button>
								</Col>
								<Col xs={24}>
									<div style={{ float: 'right', cursor: 'pointer' }} onClick={this.changeWaySearch}>Thu gọn</div>
								</Col>
							</Row>
						</div>
					}
				</div>
			</div>
		);
	}
}

export default withRouter(SearchBox);